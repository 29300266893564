import { Flex } from '@chakra-ui/react';
import AppContainer from '../common/AppContainer';
import Login from '../login/Login';

function LoginLayout() {
  return (
    <AppContainer>
      <Flex justify='center' align='center' >
        <Login />
      </Flex>
    </AppContainer>
  );
}

export default LoginLayout